import React from "react"
import Helmet from 'react-helmet'


export default function Contact () {
	return (
		<>
		<Helmet>
			<title>Terms of Use - Craft Boxing</title>
		</Helmet>
		<div className="contact-page">
		<iframe src="https://www.vcita.com/widgets/contact_form/of3i3kx0lv6jil7l?frontage_iframe=true" width="100%" height="427"  scrolling="no"  frameBorder="0">
		  <a href='https://www.vcita.com/v/of3i3kx0lv6jil7l/contact?frontage_iframe=true&invite=vr_cf_pb-of3i3kx0lv6jil7l'>Contact Form for Enriched</a>
		</iframe>
		</div>
		</>
	)
}
